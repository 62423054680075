.group-card {
  flex: 0 0 20%;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;

  .header-div {
    display: flex;
    flex-direction: column;

    .checklist-div {
      display: flex;
      justify-content: space-between;
      #timeslot {
        margin: 0;
        font-size: 1.8rem;
        padding: 0.75rem 0.75rem 0.35rem 0.75rem;
        font-weight: 500;
      }
    }

    #group-id {
      margin: 0;
      font-size: 1.2rem;
      padding: 0.35rem 0.75rem 0.35rem 0.75rem;
      background-color: #cdc8c8;
      color: #666262;
      font-family: "Courier New", Courier, monospace;
    }
  }
}
