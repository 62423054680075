.eod-box {
    display: flex;
    flex-direction: column;
  
    #header {
      margin: 1rem;
    }
  
    .search-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      margin: 1rem;
      padding: 2rem;
      border-radius: 1.8rem;
  
      .filter-bar {
        display: flex;
        flex-direction: row;
      }
    }
  
    .eod-data {
      display: flex;
      flex-direction: column;
  
      margin: 1rem;
      padding: 1rem;
  
      .header-eod {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
  
        #name {
          margin: 1rem;
          font-size: 3rem;
          font-family: monospace;
          color: rebeccapurple;
        }
  
        #date {
          margin: 1rem;
          font-size: 2rem;
          font-family: monospace;
          color: rebeccapurple;
        }
      }
    }
  }
  