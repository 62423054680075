@import "./variables.scss";

.unauth-card {
  background-color: rebeccapurple;
  width: 25rem;
  height: 35rem;
  margin: auto;
  padding: 2rem;

  display: flex;
  flex-direction: column;

  .image-div {
    display: flex;
    justify-content: center;
    #logo {
      height: 10rem;
      width: 95%;
      border: grey 2px solid;
      border-radius: 1rem;
      padding: 1rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
    }
  }
  .lg-header-comp {
    display: flex;
    flex-direction: column;

    #lg-info {
      text-align: center;
      margin: 0;
      padding: 1rem;
    }
  }

  #error {
    margin: 0;
    padding: 0.5rem;
    background-color: #d5c8c8;
    font-size: 1.6rem;
    text-align: center;
  }

  #para-1 {
    margin: 0;
    padding: 1rem 0rem 0.5rem 0rem;
    font-size: 1.1rem;
    text-align: center;
    font-weight: 400;

    #cd {
      background-color: #d5c8c8;
      padding: 0.25rem;
    }
  }

  #para-2 {
    margin: 0;
    padding: 0.5rem 0rem 1rem 0rem;
    font-size: 1.1rem;
    text-align: center;
  }
}
