@import "./variables.scss";

.app-component {
  background-color: $background-app-color;
  max-width: 100%;
  overflow-x: hidden;
  
}

.stats-bar{

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex:1;
  margin-top: 0.5vw;
 

}

.search-bar {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
 
  width: fit-content;
align-items:center;
  border-radius: 1vw;
}
.search-bar .filterline1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex:1;
  margin-top: 1vw;
  padding: 0.5vw;
}

.search-bar .historyline {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  align-items: center;
  justify-content: center;
  flex:1;

}


.search-bar .date-picker .from-date{
  height: 1rem;
}
.search-bar .filter-bar {
  display: flex;
  flex-direction: row;
  width:10vw ;
}
.filter-bar .filter-bar-form {
  width:10vw;
}
.search-bar .divider {
  margin: 0rem 0.5vw;
  height:2rem
}
