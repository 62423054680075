.parent{
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.update-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 120;
}

.display-orders{
    min-height: 60;
    min-width: 300;
    margin: 5vh;
    padding: 2vh;
    word-wrap: break-word;
}

.order-box{
    border: 0.5;
    border-style: solid;
    border-color: green;
    flex-direction: "row";
}