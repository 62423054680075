.user-card {
  margin: 0;

  .delete-order {
    display: flex;
    justify-content: flex-end;
    background-color: #f8f3f3;

    #msg {
      margin: 0;
      text-align: center;
      padding: 0.25rem 0rem;
      font-style: italic;
      font-weight: 400;
    }
  }
}
