@import "./variables.scss";

.login-card {
  width: 25rem;
  height: 30rem;
  margin: 2rem auto;
  padding: 2rem;

  display: flex;
  flex-direction: column;

  .image-div {
    display: flex;
    justify-content: center;
    #logo {
      height: 10rem;
      width: 95%;
      border: grey 2px solid;
      border-radius: 1rem;
      padding: 1rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
    }
  }
  .lg-header-comp {
    display: flex;
    flex-direction: column;

    #lg-info {
      text-align: center;
      margin: 0;
      padding: 1rem;
    }
  }

  .input-box {
    display: flex;
    flex-direction: column;

    .username {
      margin: 0rem 0rem 0.5rem 0rem;
    }
    .password {
      margin: 0.5rem 0rem 0.5rem 0rem;
    }
  }

  .button-box {
    display: flex;
    flex-direction: row-reverse;

    padding: 1rem 0rem 0rem 0rem;

    .login {
      margin: 0rem 0.5rem 0rem 0.5rem;
    }

    .reset {
      margin: 0rem 0.5rem 0rem 0rem;
    }
  }
}
