.dialog-box {
  scrollbar-width: 16px;

  scrollbar-track-color: white;
  // scrollbar-trac {
  //   background-color: #e4e4e4;
  //   border-radius: 100px;
  // }

  .-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
  }
  #para {
    padding: 0rem 0rem 0rem 1rem;
    font-style: italic;
  }
  .dialog-div {
    display: flex;
    flex-direction: column;
    margin: 0.75rem;
    padding: 0.75rem;

    .header-div {
      display: flex;
      flex-direction: row;
      #dialog-header {
        margin: 0rem;
        padding: 0rem 0rem 0.5rem 1rem;
      }
    }

    .internal-box {
      margin: 0rem;
      display: flex;
      flex-direction: column;

      .group-id-box {
        width: 30rem;
        margin: 0.5rem auto;

        .group-id-paper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          background-color: #cdc8c8;
          font-family: monospace;
          color: #666262;
        }
      }
      .div-header {
        width: 20rem;
        margin: 0.5rem auto;
        .toggle-box {
          display: flex;
          justify-content: center;
          padding: 0.75rem;

          #toggle-header {
            margin: 0.5rem;
            padding: 0rem;
          }
        }
      }

      .order-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem;

        .indiv-order {
          margin: 0rem;
          padding: 0.25rem 0.75rem;
          display: flex;
          flex-direction: row;
          flex-basis: 30%;
          justify-content: center;
        }
      }

      .send-msg-box {
        display: flex;
        flex-direction: row-reverse;
        .send-msg-paper {
          display: flex;
          flex-direction: row;
          justify-content: center;

          width: 25rem;
          margin: 0.5rem auto;

          .slack-div {
            display: flex;
            flex-direction: column;

            #slack-header {
              font-weight: 500;
              padding: 0rem 1rem;
              font-style: italic;
            }
          }

          .submit-button-div {
            display: flex;
            align-items: center;
            .submit-button {
              width: 5rem;
              height: 5rem;
              border-radius: 10rem;
              margin: 0.5rem 0rem;
            }
          }

          .collection-date {
            display: flex;
            flex-direction: row;

            padding: 0.75rem;
          }
        }
      }
    }
  }
}
.schedule-component {
  .top-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0rem 2rem 0rem 0rem;
    #header-ct {
      padding: 0rem 0rem 0.5rem 2rem;
    }

    .message-div{
      
      margin-left:1rem
    }

    #para {
      padding: 0rem 0rem 0rem 2rem;
      font-style: italic;
    }

    .group-groups {
      display: flex;
      width: 9rem;
      flex-direction: column-reverse;
      justify-content: center;

      margin: 1rem;
      border: 0.01rem solid grey;
      border-radius: 1rem;


      #groups-header {
        margin: 0rem 0rem 1rem 0rem;
        font-size: 1.3rem;
        padding: 0rem;
        text-align: center;
        color: rebeccapurple;
      }
    }
  }

  .schedule-card {
    margin: 0rem 2rem 0rem 2rem;
    display: flex;
    flex-direction: row;
    min-height: 40rem;
    flex-wrap: wrap;

    .available-data {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      #warning-header {
        margin: 10rem 0rem;
        padding: 1rem;
        text-align: center;
      }

      .time-group {
        display: flex;
        flex-direction: column;

        #group-date {
          font-size: 3rem;
          font-weight: 600;
          margin: 0;
          padding: 0rem 0rem 0.5rem 0.75rem;
          color: green;
        }

        .group-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }
  }
}
