.kanban {
    display: flex;
    align-items: flex-start;

    &__section {
        width: 400px;
        background-color: #383838;
        padding: 10px;
        border-radius: 10px;

        & ~ & {
            margin-left: 10px;
        }

        &__title {
            font-size: 1.2rem;
            font-weight: 700;
            margin: 10px 0 20px;
        }

        &__content {
            & > * ~ * {
                margin-top: 10px;
            }
        }
    } 
}