@import "./variables.scss";

.header-component {
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4.5rem;

  .logo {
    display: flex;
    height: 3.2rem;
    
    padding: 0.2rem;
  }

  
  

  
  .link-component {
   
    padding: 0rem 2rem 0rem 0rem;
    #navbar-link {
      margin: 2rem 1rem 0rem 1rem;
      font-size: 1.2rem;
      padding: 1rem;
      border-radius: 1rem;
      background-color: #9ae1a2;
      text-decoration: none;
      text-transform: none;
      //
      &:hover {
        background-color: #57a051;
      }

      &:active {
        background-color: #adf4b5;
        color: black;
      }
    }
  }
}


